import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';


class WhereToBuy extends Component {
  render() {
    return (
      <div>  
        <div id="TOOL"></div>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <Helmet>
          <link rel="preload" href="/css/IRIProductLocator.css" as="style" />
          <link rel="preload" href="/css/locator-v3.css" as="style" />
          <link rel="preload" href="//cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css" as="style" />
          <link rel="preload" href="//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css" as="style" />
          <link rel="stylesheet" href="/css/IRIProductLocator.css" />
          <link rel="stylesheet" href="/css/locator-v3.css" />
          <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css" />
          <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css" />
          
          <link rel="preload" href="/js/iri-locator-v3.js" as="script"/>
          <link rel="preload" href="/js/locator-productselect-addon-v3.js" as="script"/>
          <link rel="preload" href="//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js" as="script"/>
          
          <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr8bsYlFbTUy51HwoPdqso6C7AepRG5eE&amp;v=3" defer id="google_map_api-js"></script>
          <script type="text/javascript" src="//code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>  
          <script type="text/javascript" src="/js/iri-loader.js" defer></script>
        </Helmet>
      </div>
    );
  }
}

export default WhereToBuy;

export const query = graphql`
  query PageBackgroundQuery {
    wordpressPage(slug: { eq: "where-to-buy" }) {
      title
      content
      yoast_head_json {
        title
        description
      }
    }
  }
`;
